import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default function Tags({ data }) {
  const tags = data.allMdx.group
  tags.sort((first, second) => second.totalCount - first.totalCount)

  return (
    <Layout>
      <h2 className="text-4xl py-4 font-semibold">タグ一覧</h2>
      <hr className="my-4 md:my-8" />
      <div className="flex flex-wrap">
        {
          tags.map((tag) => (
            <div className="my-2 mr-4" key={tag.fieldValue}>
              <Link to={`/tags/${tag.fieldValue}`} className="text-blue-500">
                #{tag.fieldValue}
              </Link>
              ({tag.totalCount})
            </div>
          ))
        }
      </div>
    </Layout>
  )
}

export const Head = () => <Seo title="Tags" />

export const query = graphql`
  query {
    allMdx {
      group(field: frontmatter___tags) {
        totalCount
        fieldValue
      }
    }
  }
`
